import { Component, OnInit } from '@angular/core';
// import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp,faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'menu-collapse',
  template: `
  <a style="display:flex; flex-direction: row; justify-content: center;" (click)="collapsed = !collapsed">
  
  <fa-icon [icon]="faChevronDown" size="2x" *ngIf="!collapsed" style="display:flex; flex-direction: column; justify-content: center;"></fa-icon>
  <fa-icon [icon]="faChevronUp" size="2x" *ngIf="collapsed" style="display:flex; flex-direction: column; justify-content: center;"></fa-icon>  
</a>

  <div class="content" 
  [style.maxHeight]="collapsed ? content?.scrollHeight + 'px' : null"
  #content
>
  <ng-content></ng-content>
</div>


 `,
  styleUrls: ['./menu-collapse.component.css']
})
export class MenuCollapseComponent {
  collapsed = false;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  // {{ collapsed ? 'Minder' : 'Lees meer' }}



}
