import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './components/contact/contact.component';
import { MainComponent } from './components/main/main.component';
import { AboutComponent } from './components/about/about.component';
import { prefix } from '@fortawesome/free-solid-svg-icons';

const routes: Routes = [
  {path: '', component: MainComponent },
  {path:'home/:locate', component: MainComponent},
  {path:'contact', component: ContactComponent},
  {path:'about', component: AboutComponent}
];

@NgModule({
  imports: [    RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
