import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import  *  as  data  from  './googleMaps-style.json';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css', '../global/global.css']
})
export class ContactComponent implements AfterViewInit {

  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
  @ViewChild('mapContainerBreda', {static: false}) gmapBreda: ElementRef;

  style:  any  = (data  as  any).default;

  //Oosterhout

  map: google.maps.Map;
  lat = 51.643478;
  lng = 4.863110;
  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 15,
    styles: this.style
  };

  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
    title:"Praktijk voor Mondhygiëne de Leeuw"
  });

  //Breda

  mapBreda: google.maps.Map;
  latBreda = 51.627880;
  lngBreda = 4.740490;
  coordinatesBreda = new google.maps.LatLng(this.latBreda, this.lngBreda);

  mapOptionsBreda: google.maps.MapOptions = {
    center: this.coordinatesBreda,
    zoom: 15,
    styles: this.style 
  };

  markerBreda = new google.maps.Marker({
    position: this.coordinatesBreda,
    map: this.mapBreda,
    title: 'Praktijk voor Mondhygiëne G.M. van Barneveld-De Leeuw'
  });
  
  constructor() {};
  
  ngAfterViewInit() {   
    this.mapInitializer();
  }
  
  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.mapBreda = new google.maps.Map(this.gmapBreda.nativeElement, this.mapOptionsBreda);
    this.marker.setMap(this.map);
    this.markerBreda.setMap(this.mapBreda);
   }
}
