import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  constructor() { }

  validateComment(newComment) {
    if (newComment.title == undefined || newComment.name == undefined || newComment.description == undefined) {
      return false;
    } else {
      return true;
    }
  }

  validateCallRequest(callRequestData) {
    if (callRequestData.name == undefined || callRequestData.phone == undefined || callRequestData.infoDescription == undefined || callRequestData.email == undefined) {
      return false;
      
    } else {
      return true;
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}
