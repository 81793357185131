import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaModule } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { HeaderComponent } from './components/header/header.component';
import { CollapseContentComponent } from './components/collapse-content/collapse-content.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalSmComponent } from './components/modal-sm/modalsm.component';
import { MenuCollapseComponent } from './components/menu-collapse/menu-collapse.component';
import { CollapseSectionComponent } from './components/collapse-section/collapse-section.component';
import { FooterComponent } from './components/footer/footer.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';




@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    CollapseContentComponent,
    ContactComponent,
    AboutComponent,
    ModalComponent,
    ModalSmComponent,
    MenuCollapseComponent,
    CollapseSectionComponent,
    FooterComponent,
    ModalInfoComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    ToastrModule.forRoot({timeOut: 10000, positionClass: 'toast-top-center', preventDuplicates:true, progressBar: true, progressAnimation: 'decreasing', closeButton: true}),
    BrowserAnimationsModule,
    RecaptchaModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
