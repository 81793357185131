import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../global/global.css']
})
export class HeaderComponent implements OnInit {

  constructor(private toastr: ToastrService) { }

  ngOnInit() {
  }
  // emailSent(newMail: boolean){
  //   if(newMail === true){
  //     this.toastr.success('Uw bericht is verstuurd, er is een kopie van uw bericht naar uw e-mailadres verstuurd.', 'Verzonden', {positionClass: 'toast-top-full-width'});
  //   } else{
  //     this.toastr.warning('Uw bericht is niet verstuurd, probeer het nogmaals of neem direct contact met ons op', 'Niet verzonden', {positionClass: 'toast-top-full-width'});
  //   }
  // }

}
