import { Component, ViewChild, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { Inject, Injectable} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './components/global/global.css']
})
export class AppComponent {
  title = 'mho-app';
  
  constructor(){}

}
