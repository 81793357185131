import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ValidateService} from '../../services/validate.service';
import { CallRequestService } from '../../services/callRequest.service';
import { ToastrService } from 'ngx-toastr';
import { faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal-sm',
  templateUrl: './modalsm.component.html',
  styleUrls: ['./modalsm.component.css']
})
export class ModalSmComponent implements OnInit {
  closeResult: string;
  name: string;
  infoDescription:string;
  phone: number;
  email: string;

  faEnvelope = faEnvelope;
  faTimes = faTimes;

  signed: boolean = false;

  @Output() newMailEvent = new EventEmitter<boolean>();

  constructor(private modalService: NgbModal, private validateService:ValidateService,  private callRequestService:CallRequestService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  resolved(captchaResponse: string) {
    this.signed = true;
  }

  onCallRequestSubmit(){

    let callRequestData = {
       name : this.name,
       email: this.email,
       phone: this.phone,
       infoDescription: this.infoDescription
    };

    if(!this.validateService.validateCallRequest(callRequestData)){
      this.toastr.warning('Vul alstublieft alle gegevens juist in', 'Waarschuwing');
      return false;
    }

    if(!this.validateService.validateEmail(callRequestData.email)){
      this.toastr.warning('Vul alstublieft een geldig e-maildres in. Vb. "jandevries@voorbeeld.nl"', 'Waarschuwing');
      return false;
    }

    console.log(callRequestData);
    this.callRequestService.sendCallRequest(callRequestData).subscribe( res =>{
      console.log(res);
      this.newMailEvent.emit(true);
    });

    this.modalService.dismissAll();
    this.name = '';
    this.email = '';
    this.phone = null;
    this.infoDescription = '';

  }


  open(content) {
    this.signed = false;
    this.modalService.open(content,  {ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.signed = false;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
