import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css', '../global/global.css']
})
export class MainComponent implements OnInit {

  @ViewChild('section1', { static: true })
  section1: ElementRef;

  @ViewChild('section2', { static: true })
  section2: ElementRef;

  @ViewChild('section3', { static: true })
  section3: ElementRef;

  @ViewChild('section4', { static: true })
  section4: ElementRef;

  param: string;

  
  constructor(private toastr: ToastrService, private route: ActivatedRoute){}
  ngOnInit(){
    this.route.params.subscribe(params => {
      this.param = params.locate;
      this.scrollSection(this.param);
    })
  }
    // this.toastr.success('Uw bericht is verstuurd, er is een kopie van uw bericht naar uw e-mailadres verstuurd.', 'Verzonden', {positionClass: 'toast-top-full-width'});
  
  scrollSection(param){
    if(param === 'de_mondhygienist' || param === 'tarieven'){    
    this.section3.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'}); 
    }else if(param === 'wat_doen_wij'){
      this.section1.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
    }else if (param === 'wanneer'){
      this.section2.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
    else if (param === 'welkom'){
      window.scroll(0,0);
    }
    
  }

  emailSent(newMail: boolean){
    if(newMail === true){
      this.toastr.success('Uw bericht is verstuurd, er is een kopie van uw bericht naar uw e-mailadres verstuurd.', 'Verzonden', {positionClass: 'toast-top-full-width'});
    } else{
      this.toastr.warning('Uw bericht is niet verstuurd, probeer het nogmaals of neem direct contact met ons op', 'Niet verzonden', {positionClass: 'toast-top-full-width'});
    }
  }
}
