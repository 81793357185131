import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class CallRequestService {

  localurl: string = "http://localhost:3000";

  constructor(private http:HttpClient) { }

  sendCallRequest(callRequestData){
    let headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.post('/callRequest', callRequestData, {headers: headers}) 
    .pipe(map(res  => res));
  }

}
