import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'collapse-section',
  template: `
  <div>
  <button class="btn" (click)="collapsedSection = !collapsedSection">
  {{ collapsedSection ? 'Minder' : 'Lees meer' }}
  </button>
    <div class="content" 
      [style.maxHeight]="collapsedSection ? content?.scrollHeight + 'px' : null"
      #content
    >
      <ng-content></ng-content>
    </div>
</div>
   `,
  styleUrls: ['./collapse-section.component.css']
})
export class CollapseSectionComponent {
  collapsedSection = false;

}
