import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'collapse-content',
  template: `
    <div class="content" 
      [style.maxHeight]="collapsed ? content?.scrollHeight + 'px' : null"
      #content
    >
      <ng-content></ng-content>
    </div>

    <button class="btn" (click)="collapsed = !collapsed">
      {{ collapsed ? 'Minder' : 'Lees meer' }}
    </button>
   `,
  styleUrls: ['./collapse-content.component.css']
})
export class CollapseContentComponent {
  collapsed = false;

}
